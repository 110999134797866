import React, { useState, useCallback } from 'react'
import AWS from 'aws-sdk'
import Toast from 'src/components/Toast'
import Textarea from 'src/components/ContactPage/Textarea'
import Input from 'src/components/ContactPage/Input'
import SendButton from 'src/components/ContactPage/SendButton'

import styles from './styles.module.css'

export default ({ placeholders, email, errorMessage }) => {
  const [name, updateName] = useState('')
  const [emailFrom, updateEmailFrom] = useState('')
  const [message, updateMessage] = useState('')
  const [subject, updateSubject] = useState('')
  const [error, updateError] = useState(false)

  const updateNameValue = useCallback(
    event => updateName(event.target.value),
    []
  )
  const updateEmailFromValue = useCallback(
    event => updateEmailFrom(event.target.value),
    []
  )
  const updateMessageValue = useCallback(
    event => updateMessage(event.target.value),
    []
  )
  const updateSubjectValue = useCallback(
    event => updateSubject(event.target.value),
    []
  )

  const send = event => {
    event.preventDefault()
    const IdentityPoolId = 'us-east-1:fbabd776-a84e-43a9-a2e8-5cf051cc1af7'
    AWS.config.update({ region: 'us-east-1' })
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId,
    })
    const params = {
      Destination: {
        ToAddresses: [`${email}`],
      },
      Message: {
        Body: {
          Html: {
            Charset: 'UTF-8',
            Data: `<h1>FROM ${emailFrom} : ${name}</h1>.<br/><h2>${subject}</h2><br/> ${message}`,
          },
        },
        Subject: {
          Charset: 'UTF-8',
          Data: 'Inquiries',
        },
      },
      Source: 'no-reply@mieuxplanifier.com',
    }
    updateError(true)

    const sendPromise = new AWS.SES({ apiVersion: '2010-12-01' })
      .sendEmail(params)
      .promise()
    sendPromise
      .then(() => {
        window.location.reload()
      })
      .catch(() => {
        updateError(true)
        setTimeout(() => close(), 5000)
      })
  }

  const close = () => {
    updateError(false)
  }

  return (
    <div className={styles.contact}>
      <Toast show={error} text={errorMessage} close={close} />
      <form className={styles.form} onSubmit={send}>
        <div className={styles.info}>
          <Input
            value={name}
            updateValue={updateNameValue}
            placeholder={placeholders.value.name.value}
            type={'text'}
          />
          <Input
            value={subject}
            updateValue={updateSubjectValue}
            placeholder={placeholders.value.subject.value}
            type={'text'}
          />
          <Input
            value={emailFrom}
            updateValue={updateEmailFromValue}
            placeholder={placeholders.value.email.value}
            type={'email'}
          />
        </div>
        <div className={styles.message}>
          <Textarea
            value={message}
            updateValue={updateMessageValue}
            placeholder={placeholders.value.message.value}
          />
          <SendButton callToAction={placeholders.value.callToAction.value} />
        </div>
      </form>
    </div>
  )
}
