import React from 'react'

import styles from './styles.module.css'

export default ({ updateValue, value, placeholder }) => {
  return (
    <textarea
      className={styles.message}
      placeholder={placeholder}
      value={value}
      onChange={updateValue}
      required
    />
  )
}
