import React from 'react'
import ReactDOM from 'react-dom'

import styles from './styles.module.css'

const Modal = ({ children }) => {
  const remove = () => {
    document.getElementById('outter-modal').remove()
    document.querySelector('html').style.overflow = null
  }
  const stopPropagation = e => e.stopPropagation()
  return (
    <div
      className={styles.modal}
      id="modal"
      onClick={remove}
      onKeyPress={remove}
      role="button"
      tabIndex="0"
    >
      <div
        className={styles.inner}
        onClick={stopPropagation}
        onKeyPress={stopPropagation}
        role="button"
        tabIndex="0"
      >
        {children}
      </div>
    </div>
  )
}

export default children => {
  const container = document.createElement('div')
  container.id = 'outter-modal'
  document.body.appendChild(container)
  ReactDOM.render(<Modal children={children} />, container)
  document.getElementById('modal').focus()

  document.querySelector('html').style.overflow = 'hidden'
}
