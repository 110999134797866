import { graphql } from 'gatsby'
import classNames from 'classnames'
import React from 'react'
import { useIntl } from 'react-intl'
import { ReactSVG } from 'react-svg'
import NonStretchedImage from 'src/components/NonStretchedImage'
import Modal from 'src/components/Modal'
import Contact from 'src/components/AboutPage/Team/Contact'

import styles from './styles.module.css'

export default ({ AboutPageData }) => {
  const intl = useIntl()
  return (
    <div className={styles.teamContainer}>
      <div className={styles.title}>{AboutPageData.team.value.title.value}</div>
      <div className={styles.background} />
      {AboutPageData.team.value.teamMembers.value.map((teamMember, index) => {
        const contactModal = () =>
          Modal(
            <Contact
              placeholders={AboutPageData.contactPlaceholders}
              email={teamMember.email.value}
              errorMessage={intl.formatMessage({ id: 'error' })}
            />
          )
        return (
          <div
            key={teamMember.cockpitId}
            className={classNames(styles.teamMember, {
              [styles.reversed]: index % 2 !== 0,
            })}
          >
            <div
              className={classNames(styles.imageContainer, {
                [styles.justifyStart]: index % 2 === 0,
                [styles.justifyEnd]: index % 2 !== 0,
              })}
            >
              <div className={styles.imageBackground}>
                <NonStretchedImage
                  className={styles.image}
                  fluid={teamMember.image.value.childImageSharp.fluid}
                  alt={teamMember.name.value}
                />
              </div>
            </div>

            <div className={styles.teamMemberTextContainer}>
              <div
                className={classNames(
                  styles.teamMemberTitlesContainer,
                  styles.justifyStart,
                  styles.showOnTabletAndMobile,
                  {
                    [styles.hidden]: index % 2 === 0,
                    [styles.justifyEndTablet]: index % 2 === 0,
                  }
                )}
              >
                <div className={styles.teamMemberTitles}>
                  {teamMember.title &&
                    teamMember.title.value.map(title => (
                      <div key={title.cockpitId}>{title.name.value}</div>
                    ))}
                </div>
              </div>
              <div
                className={classNames(
                  styles.teamMemberNameDescriptionContainer,
                  {
                    [styles.alignEnd]: index % 2 !== 0,
                    [styles.alignStart]: index % 2 === 0,
                  }
                )}
              >
                <div className={styles.teamMemberNameDescription}>
                  <div
                    className={classNames(
                      styles.teamMemberTitlesContainer,
                      styles.mediumPortraitTitles
                    )}
                  >
                    <div className={styles.teamMemberTitles}>
                      {teamMember.title &&
                        teamMember.title.value.map(title => (
                          <div key={title.cockpitId}>{title.name.value}</div>
                        ))}
                    </div>
                  </div>
                  <div className={styles.teamMemberName}>
                    {teamMember.name.value}
                  </div>
                  <div className={styles.teamMemberDescription}>
                    {teamMember.description.value}
                  </div>
                  <div className={styles.contactButtons}>
                    <div
                      role="button"
                      onClick={contactModal}
                      onKeyPress={contactModal}
                      tabIndex="0"
                    >
                      <ReactSVG
                        src={AboutPageData.emailIcon.value.publicURL}
                        className={styles.email}
                      />
                    </div>
                    <a href={`tel:${teamMember.phone.value}`}>
                      <ReactSVG
                        src={AboutPageData.phoneIcon.value.publicURL}
                        className={styles.phone}
                      />
                    </a>
                  </div>
                </div>
              </div>

              <div
                className={classNames(
                  styles.teamMemberTitlesContainer,
                  styles.hideOnTabletAndMobile,
                  {
                    [styles.hidden]: index % 2 !== 0,
                  }
                )}
              >
                <div className={styles.teamMemberTitles}>
                  {teamMember.title &&
                    teamMember.title.value.map(title => (
                      <div key={title.cockpitId}>{title.name.value}</div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export const query = graphql`
  fragment TeamAbout on CockpitAbout {
    contactPlaceholders {
      value {
        callToAction {
          value
        }
        message {
          value
        }
        subject {
          value
        }
        name {
          value
        }
        email {
          value
        }
      }
    }
    phoneIcon {
      value {
        publicURL
      }
    }
    emailIcon {
      value {
        publicURL
      }
    }
    team {
      value {
        title {
          value
        }
        teamMembers {
          value {
            cockpitId
            name {
              value
            }
            title {
              value {
                cockpitId
                name {
                  value
                }
              }
            }
            description {
              value
            }
            image {
              value {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            phone {
              value
            }
            email {
              value
            }
          }
        }
      }
    }
  }
`
