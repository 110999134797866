import classNames from 'classnames'
import React, { useEffect } from 'react'

import styles from './styles.module.css'

export default ({ show, text, close }) => {
  useEffect(() => {
    setTimeout(() => {
      close()
    }, 5000)
  }, [close])
  return (
    <div
      role="button"
      className={classNames(styles.toast, {
        [styles.show]: show,
      })}
      id="toast"
      onClick={close}
      onKeyDown={close}
      tabIndex="0"
    >
      <p>{text}</p>
    </div>
  )
}
