import React from 'react'

import styles from './styles.module.css'

export default ({ callToAction }) => {
  return (
    <button
      className={styles.button}
      type="submit"
    >
      <div className={styles.text}>
        {callToAction}
      </div>
      <div className={styles.line} />
      <div className={styles.arrowTip} />
    </button>
  )
}