import classNames from 'classnames'
import { graphql } from 'gatsby'
import HistorySlider from 'src/components/AboutPage/HistorySlider'
import NonStretchedImage from 'src/components/NonStretchedImage'
import React from 'react'

import styles from './styles.module.css'

export default ({ historyData }) => {
  const [year, setYear] = React.useState(0)

  const getAllYears = () => {
    const allYears = []
    historyData.years.value.map(year =>
      allYears.push(parseInt(year.value.year.value))
    )
    return allYears.sort((a, b) => a - b)
  }
  return (
    <div className={styles.historyContainer}>
      <div className={styles.title}>{historyData.title.value}</div>
      <div className={styles.descriptionContainer}>
        <div className={styles.sliderContainer}>
          <HistorySlider
            value={year}
            allYears={getAllYears()}
            onChange={setYear}
          />
        </div>
        <div className={styles.yearDataContainer}>
          {historyData.years.value.map((currentYear, index) => (
            <div key={currentYear.value.description.value}>
              <div
                className={classNames(styles.description, {
                  [styles.hidden]: index !== year,
                })}
              >
                {currentYear.value.description.value}
              </div>
              {currentYear.value.images.value.map((image, imageIndex) => (
                <div key={image.id}>
                  <NonStretchedImage
                    className={classNames(styles.image, {
                      [styles.hidden]: index !== year,
                      [styles.firstImage]: imageIndex === 0,
                      [styles.secondImage]: imageIndex === 1,
                      [styles.thirdImage]: imageIndex === 2,
                    })}
                    fluid={image.childImageSharp.fluid}
                  />
                  <NonStretchedImage
                    className={classNames(styles.image, styles.gradientImage, {
                      [styles.hidden]: index !== year,
                      [styles.firstImage]: imageIndex === 0,
                      [styles.secondImage]: imageIndex === 1,
                      [styles.thirdImage]: imageIndex === 2,
                    })}
                    fluid={image.childImageSharp.gradientImage}
                  />
                </div>
              ))}
            </div>
          ))}
          <div className={styles.imageContainer}></div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HistoryAbout on CockpitAbout {
    history {
      value {
        title {
          value
        }
        years {
          value {
            value {
              description {
                value
              }
              year {
                value
              }
              images {
                value {
                  id
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                    gradientImage: fluid(
                      duotone: {
                        highlight: "#00ecfa"
                        shadow: "#2929e0"
                        opacity: 60
                      }
                    ) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
