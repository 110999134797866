import React from 'react'

import styles from './styles.module.css'

export default ({ updateValue, value, placeholder, type }) => {
  return (
      <input
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={updateValue}
        className={styles.input}
        required
      />
  )
}