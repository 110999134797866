import { graphql } from 'gatsby'
import React from 'react'

import styles from './styles.module.css'

const Catchphrase = ({ catchphraseData }) => {
  return (
    <div className={styles.textContainer}>
      <div className={styles.title}>{catchphraseData.title.value}</div>
      <div className={styles.subtitle}>{catchphraseData.subtitle.value}</div>
      <div className={styles.description}>
        {catchphraseData.description.value}
      </div>
    </div>
  )
}

export default Catchphrase

export const query = graphql`
  fragment CatchphraseAbout on CockpitAbout {
    catchPhrase {
      value {
        title {
          value
        }
        subtitle {
          value
        }
        description {
          value
        }
      }
    }
  }
`
