import Catchphrase from 'src/components/AboutPage/CatchPhrase'
import { graphql } from 'gatsby'
import Header from 'src/components/Header'
import History from 'src/components/AboutPage/History'
import Layout from 'src/components/Layout'
import React, { Component } from 'react'
import Team from 'src/components/AboutPage/Team'

import styles from './styles.module.css'

class AboutPage extends Component {
  render() {
    const {
      pageContext,
      data: { about },
    } = this.props

    return (
      <Layout pageContext={pageContext}>
        <Header
          title={about.header.value.title.value}
          description={about.header.value.description.value}
        />
        <Catchphrase catchphraseData={about.catchPhrase.value} />
        <div className={styles.verticalLine} />
        <History historyData={about.history.value} />
        <div className={styles.verticalLine} />
        <Team AboutPageData={about} />
      </Layout>
    )
  }
}

export default AboutPage

export const query = graphql`
  query($locale: String) {
    about: cockpitAbout(lang: { eq: $locale }) {
      header {
        value {
          description {
            value
          }
          title {
            value
          }
        }
      }
      ...CatchphraseAbout
      ...TeamAbout
      ...HistoryAbout
    }
  }
`
