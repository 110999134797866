import classNames from 'classnames'
import React from 'react'

import styles from './styles.module.css'

export default ({ value, allYears, onChange }) => {
  return (
    <div className={styles.sliderContainer}>
      <div className={styles.labelsContainer}>
        {allYears.map((year, index) => (
          <div key={year} className={styles.labelContainer}>
            <div
              className={classNames(styles.label, {
                [styles.active]: index === value,
              })}
            >
              {year}
            </div>
            <div
              className={classNames(styles.line, {
                [styles.active]: index === value,
              })}
            />
          </div>
        ))}
      </div>
      <input
        orient="vertical"
        type="range"
        value={value}
        min={0}
        max={allYears.length - 1}
        step={1}
        className={styles.slider}
        onChange={event => onChange(event.target.valueAsNumber)}
      />
    </div>
  )
}
